import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Litigation({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Litigation Lawyers | Tecklenburg, Jenkins & Jacoby, LLC"
        description="Tecklenburg, Jenkins & Jacoby has represented clients in both state and
            federal courts throughout the Southeast. Our attorneys have extensive experience in a wide
            range of business litigation and commercial litigation."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-litigation/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>Top Civil Litigation Lawyers In South Carolina</h1>
          <h2>Civil Litigation Defense</h2>
          <p>
            Tecklenburg, Jenkins & Jacoby has represented clients in both state
            and federal courts throughout the Southeast, such as South Carolina
            and North Carolina. Our attorneys have extensive experience in a
            wide range of business litigation and commercial litigation. In our
            comprehensive civil litigation defense practice, Tecklenburg &
            Jenkins has represented clients in both state and federal courts
            throughout the Southeast. Our litigation experience includes
            disputes arising from soured business relationships, employment and
            personal injury. Some of the matters for which we provide
            professional legal advice and representation include:
          </p>
          <ul>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
                  Maritime Defense
                </Link>
              </h3>
            </li>
            <li>
              <h3>Environmental Claims</h3>
            </li>
            <li>
              <h3>Seamen or Crew Claims, including “Maintenance and Cure”</h3>
            </li>
            <li>
              <h3>Limitation of Liability Act Petitions</h3>
            </li>
            <li>
              <h3>Cargo Claims</h3>
            </li>
            <li>
              <h3>Products Liability and Warranty Claims</h3>
            </li>
            <li>
              <h3>Salvage</h3>
            </li>
          </ul>
          <h2>Employer Liability Defense</h2>
          <ul>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
                  Jones Act Claims
                </Link>
              </h3>
            </li>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
                  Longshore and Harbor Workers Compensation Claims
                </Link>
                (“905(b) claims”)
              </h3>
            </li>
            <li>
              <h3>FELA Claims</h3>
            </li>
            <li>
              <h3>Other Employment Disputes</h3>
            </li>
          </ul>
          <h2>
            <Link to="/practice-areas/south-carolina-business-law">
              Business
            </Link>{" "}
            Litigation Defense
          </h2>
          <ul>
            <li>
              <h3>Breach of Contract</h3>
            </li>
            <li>
              <h3>Employment Disputes</h3>
            </li>
            <li>
              <h3>Business Sale Disputes</h3>
            </li>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-real-estate-law">
                  Real Estate Litigation
                </Link>
              </h3>
            </li>
            <li>
              <h3>Landlord-Tenant Disputes</h3>
            </li>
            <li>
              <h3>Collections</h3>
            </li>
            <li>
              <h3>Partnership Disputes</h3>
            </li>
            <li>
              <h3>Insurance Coverage Disputes</h3>
            </li>
            <li>
              <h3>Zoning Disputes</h3>
            </li>
          </ul>
          <h2>General Personal Injury Defense</h2>
          <ul>
            <li>
              <h3>Employer Liability</h3>
            </li>
            <li>
              <h3>Premises Liability</h3>
            </li>
            <li>
              <h3>Product Liability</h3>
            </li>
            <li>
              <h3>Construction or Workplace Accidents</h3>
            </li>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-transportation-law">
                  Transportation Accidents
                </Link>
              </h3>
            </li>
            <li>
              <h3>Other Defense Against Personal Injury Claims</h3>
            </li>
          </ul>
          <h2>Litigation Defense Founded on experience and Integrity</h2>
          <p>
            We invite you to contact us for more information about quality
            representation and how we can assist to resolve your legal matters.
          </p>
          <h2>Civil Litigation</h2>
          <p>
            The civil litigation process, like most legal matters, need not be
            lengthy and tedious. Whether you have been sued and are on the
            defense or must pursue a claim as a plaintiff, you will need an
            experienced attorney who can identify and cut through the legal
            issues with appropriate research, use it to present persuasive
            positions, and reach speedy resolutions, whether through litigation
            or alternative dispute resolution. At the Tecklenburg, Jenkins &
            Jacoby law firm, our attorneys specialize in the representation and
            defense of clients who have civil litigation actions brought upon
            them; however, we will also handle certain plaintiffs’ litigation,
            cases involving contract disputes, collections, business litigation
            and product liability.
          </p>
          <p>
            <Link to="/contact">Contact us now</Link> to put our civil
            litigation experience to work for you.
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query LitigationSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-litigation" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
